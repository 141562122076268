var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ais-instant-search',{attrs:{"index-name":"demo_ecommerce","search-client":_vm.searchClient}},[_c('b-card',{staticClass:"d-flex flex-row contact-app",attrs:{"no-body":""}},[_c('div',{staticClass:"left-part border-right",class:_vm.showleftpart ? '' : 'show-left-part'},[_c('div',{staticClass:"p-3 text-right border-bottom d-block d-lg-none"},[_c('i',{staticClass:"mdi mdi-close",on:{"click":_vm.toggleSide}})]),_c('ais-clear-refinements',{attrs:{"class-names":{
          'ais-ClearRefinements': 'p-3',
          'ais-ClearRefinements-button': 'btn btn-primary btn-block',
        }}}),_c('ais-refinement-list',{staticClass:"mt-3",attrs:{"class-names":{
          'ais-RefinementList-list': 'list-group list-group-flush',
          'ais-RefinementList-item': 'list-group-item',
          'ais-RefinementList-label': 'mb-0',
          'ais-SearchBox-form': 'input-group p-3 border-top',
          'ais-SearchBox-input': 'form-control',
          'ais-SearchBox-submit': 'btn btn-primary',
          'ais-SearchBox-submitIcon': 'text-white',
          'ais-SearchBox-reset': 'btn btn-danger d-none',
        },"attribute":"brand","searchable":""}}),_c('h4',{staticClass:"p-3 mb-0 mt-3"},[_vm._v("Price sort")]),_c('ais-numeric-menu',{attrs:{"class-names":{
          'ais-NumericMenu-list': 'list-group list-group-flush',
          'ais-NumericMenu-item': 'list-group-item',
          'ais-NumericMenu-label': 'mb-0',
        },"attribute":"price","items":[
          { label: 'All' },
          { label: '<= 10$', end: 10 },
          { label: '10$ - 100$', start: 10, end: 100 },
          { label: '100$ - 500$', start: 100, end: 500 },
          { label: '>= 500$', start: 500 },
        ]}}),_c('ais-configure',{attrs:{"hitsPerPage":12}})],1),_c('div',{staticClass:"right-part min-h"},[_c('b-button',{staticClass:"d-block d-lg-none m-3",attrs:{"variant":"danger"}},[_c('i',{staticClass:"mdi mdi-menu",on:{"click":_vm.toggleSide}})]),_c('ais-search-box',{attrs:{"class-names":{
          'ais-SearchBox-form': 'input-group p-3',
          'ais-SearchBox-input': 'form-control',
          'ais-SearchBox-submit': 'btn btn-primary',
          'ais-SearchBox-submitIcon': 'text-white',
          'ais-SearchBox-reset': 'btn btn-danger d-none',
        }}}),_c('ais-hits',{attrs:{"class-names":{
          'ais-Hits': '',
          'ais-Hits-list': 'row p-0 no-gutters',
          'ais-Hits-item': 'col-xl-4 col-lg-6 col-md-6',
          // ...
        }},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return _c('div',{staticClass:"border-right border-top"},[_c('div',{staticClass:"d-flex py-4 px-2"},[_c('div',{staticClass:"text-center"},[_c('img',{staticClass:"mx-4",attrs:{"src":item.image,"align":"left","alt":item.name}}),_c('b-badge',{attrs:{"variant":"info"}},[_vm._v("$ "+_vm._s(item.price))])],1),_c('div',{},[_c('h4',{staticClass:"title"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"py-1 subtitle-2"},[_c('ais-highlight',{attrs:{"attribute":"name","hit":item}})],1),_c('div',{staticClass:"d-flex mt-4"},[_c('b-button',{attrs:{"variant":"outline-info"}},[_vm._v(" Add to cart ")]),_c('b-button',{staticClass:"mx-2",attrs:{"variant":"outline-danger"}},[_c('i',{staticClass:"mdi mdi-heart"})])],1)])])])}}])}),_c('ais-pagination',{attrs:{"class-names":{
          'ais-Pagination':
            'justify-content-center d-flex border-top pt-4 pb-2',
          'ais-Pagination-list': 'pagination b-pagination',
          'ais-Pagination-item': 'page-item',
          'ais-Pagination-item--selected': 'active',
          'ais-Pagination-link': 'page-link',
          // ...
        }}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }